.container {
  display: flex;
}

.column {
  flex: 1;
  padding: 16px;
}

/* Optional: Add any additional styling for each column, if desired */
.column-1 {
  background-color: #f5f5f5;
}

.column-2 {
  background-color: #e0e0e0;
}
