body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


  /* Global styles */
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #1c1c1c;
    color: #fff;
    line-height: 1.5;
  }

  .wrapper {
    padding: 20px;
  }
  
  a {
    color: #fff;
  }
  
  /* Header styles */

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #3a3a3a;
  color: #fff;
}

.column {
  display: flex;
  align-items: center;
}

.column:nth-child(1) {
  flex: 2; /* This will take 2/3rd of the space */
}

.column:nth-child(2) {
  flex: 1; /* This will take 1/3rd of the space */
  justify-content: flex-end;
}

header h1 {
  margin: 0;
}

nav ul {
  display: flex;
  gap: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .header {
      flex-direction: column;
  }

  .column:nth-child(1),
  .column:nth-child(2) {
      flex: auto; /* On small screens, let each column take the necessary space */
  }

}

  
  /* Main content styles */
  main {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading styles */
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
  
  h1 {
    font-size: 38px;
  }
  
  h2 {
    font-size: 28px;
  }
  
  h3 {
    font-size: 20px;
  }
  
  /* Paragraph styles */
  p {
    margin-bottom: 20px;
  }
  
  .topics {
    clear: both;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-flex; 
    margin: 0;  
  }




  .buttons {
    clear: both;
  }

  /* Button styles */
  button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  button:hover {
    background-color: #e6e6e6;
  }
  
  /* Form styles */
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  input[type="text"], textarea {
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #333;
    color: #fff;
  }
  
  input[type="submit"] {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #e6e6e6;
  }


  .NowPlaying {
    padding: 10px;
  }

  
  /* Audio player styles */
  .audio-player {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    clear: both;
  }
  
  .audio-player audio {
    width: 100%;
    clear: both;
  }
  
  .audio-player .infobox {
    flex: 1;
    clear: both;
  }
  
  .audio-player .infobox h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #fff;
  }
  
  .audio-player .infobox p {
    font-size: 16px;
    margin: 0;
    color: #ccc;
    clear: both;
  }

  .progress-bar {
    display: none;
  }
  
  .audio-player .progress-bar {
    height: 20px;
    width: 100%;
    background-color: #555;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    clear: both;
  }
  
  .audio-player .progress-bar .progress {
    height: 100%;
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1abc9c;
    cursor: pointer;
  }
  
  .audio-player .progress-bar .progress::-moz-range-thumb {
    background-color: #1abc9c;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
  
  .audio-player .progress-bar .progress::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #1abc9c;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
  
  .audio-player button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .audio-player button:hover {
    background-color: #e6e6e6;
  }

  /* Audio player styles 2 */
  audio::-webkit-media-controls-timeline {
      transform: scaley(10);
  }


