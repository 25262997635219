.settings-dropdown {
  position: relative;
  display: inline-block;
  z-index: 10000;
}

.settings-button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.settings-menu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 300px;
  margin-top: 4px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  list-style-type: none;
  padding: 8px 0;
}

.settings-item {
  padding: 8px 16px;
  cursor: pointer;
  color: #000000;
  display: block;
}

.settings-item:hover {
  background-color: #f5f5f5;
}

.settings-item input {
  min-width: 90%;
  text-align: center;

}
